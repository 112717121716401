import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt from "jwt-decode";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import "./auth.css";
import ENV from "../../constants/Environment";
import { AdminContext } from "../context/Context";

const { apiUrl, onwardDashboardUrl } = ENV;
const Login = () => {
  const [value, setValue] = useContext(AdminContext);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // error handling
  const [toggleEmailError, setToggleEmailError] = useState(false);
  const [togglePasswordError, setTogglePasswordError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [alert, setAlert] = useState(false);
  const [token, setToken] = useState(null);
  // toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // user redirect
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setUsername(e.target.value);
    if (userName === "") {
      setToggleEmailError(false);
      setEmailError("");
    }
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    if (password.length === 0) {
      setTogglePasswordError(false);
      setPasswordError("");
    }
  };
  const handleSubmit = (e) => {
    if (userName.length === 0) {
      setToggleEmailError(true);
      setEmailError("Email is required");
    }
    if (password.length === 0) {
      setTogglePasswordError(true);
      setPasswordError("Password is required");
    }
    axios
      .post(`${apiUrl}/token/generateToken`, {
        userName: userName,
        password: password,
      })
      .then((response) => {
        const token = response.data.accessToken.toString();
        let decode = jwt(token);
        setToken(token);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("firstName", decode.first_name);
        localStorage.setItem("lastName", decode.last_name);
        localStorage.setItem("userid", decode.user_id);
        localStorage.setItem("partnerId", decode.partner_id);
        localStorage.setItem("admin", decode.admin);
        if (decode.dispatcher === true) {
          setValue("Dispatcher");
          localStorage.setItem("Page", "Dispatch");
          navigate("/");
        }
        if (decode.admin === true) {
          setValue("Concierge");

          localStorage.setItem("Page", "Concierge");
          navigate("/");
        }
        window.location.reload();
      })
      .catch((error) => {
        if (userName.length === 0 || password.length === 0) {
          setAlert(false);
        } else {
          setAlert(true);
        }
      });
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="auth-form-wrapper">
      <form className="auto-form-container">
        {alert ? (
          <Alert variant="filled" severity="error">
            Invalid Credentials
          </Alert>
        ) : (
          ""
        )}
        <h3 className="auth-logo">ONWARD</h3>
        <h4 className="auth-title">Sign In</h4>
        <TextField
          id="outlined"
          label="Email"
          value={userName}
          onChange={handleEmail}
          error={toggleEmailError}
          helperText={emailError}
          required
        />

        <TextField
          id="outlined-password-input"
          label="Password"
          autoComplete="current-password"
          onChange={handlePassword}
          error={togglePasswordError}
          helperText={passwordError}
          required
          value={password}
          onKeyDown={handleKeypress}
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          style={{ width: "100%", marginTop: "10px", background: "#215261" }}
          variant="contained"
          onClick={handleSubmit}
        >
          SIGN IN
        </Button>
        <div className="auto-form-text-link-container">
          <a href={`${onwardDashboardUrl}/resetPassword`}>
            <p className="forgot-password">Forgot Password?</p>
          </a>
          <p className="have-account">
            Don't have an account?{" "}
            <a
              href="mailto:support@onwardrides.com?subject=Onward Rides Sign-up Request"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
