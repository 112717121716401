import { BadgeList } from "./Badges";
import {
  BADGE_TYPES,
  badgeTypeByRideType,
  badgeTypeByTransportTypeId,
} from "./badge-types";

export function RideDetailsBadges({ id, rideType, transportTypeId }) {
  return (
    <BadgeList>
      <BadgeList.Badge
        id={id}
        details={badgeTypeByTransportTypeId(transportTypeId)}
      />
      <BadgeList.Badge id={id} details={badgeTypeByRideType(rideType)} />
    </BadgeList>
  );
}

export function RideMetaBadges({
  id,
  hasStairs,
  isBariatric,
  isLongDistance,
  isInsurance,
}) {
  return (
    <>
      {(hasStairs || isBariatric || isLongDistance || isInsurance) && (
        <BadgeList>
          {isBariatric && (
            <BadgeList.Badge id={id} details={BADGE_TYPES.BARIATRIC} />
          )}

          {hasStairs && (
            <BadgeList.Badge id={id} details={BADGE_TYPES.STAIRS} />
          )}

          {isLongDistance && (
            <BadgeList.Badge id={id} details={BADGE_TYPES.LONG_DISTANCE} />
          )}

          {isInsurance && (
            <BadgeList.Badge id={id} details={BADGE_TYPES.INSURANCE} />
          )}
        </BadgeList>
      )}
    </>
  );
}
