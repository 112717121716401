import React from "react";

const VehicleDescription = ({ vehicleSummary }) => {
  return (
    <>
      {!vehicleSummary || vehicleSummary.length === 3 ? null : (
        <div style={{ display: "flex", gap: "5px", maxWidth: "260px" }}>
          <p style={{ width: "62px" }}>Vehicle:</p>
          <p style={{ fontWeight: 600, fontSize: "13px" }}>
            {" "}
            {vehicleSummary}{" "}
          </p>
        </div>
      )}
    </>
  );
};

export default VehicleDescription;
