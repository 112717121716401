import { Tooltip } from "@mui/material";
import React from "react";
import { FontWeight } from "../../globalStyles/emotion";

const RideNotes = ({ rideNotes }) => {
  if (rideNotes === null) {
    rideNotes = "";
  }
  return (
    <>
      {rideNotes.length > 100 ? (
        <Tooltip enterDelay={500} title={rideNotes} placement="top">
          <FontWeight>{rideNotes.slice(0, 100)}...</FontWeight>
        </Tooltip>
      ) : (
        <FontWeight> {rideNotes}</FontWeight>
      )}
    </>
  );
};

export default RideNotes;
