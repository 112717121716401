import ENV from "../constants/Environment";
import { createConsumer } from "@rails/actioncable";

class ActionCableService {
  constructor() {
    const webSocketUrl = `${ENV.onwardWsUrl}?token=${localStorage.getItem(
      "accessToken"
    )}`;
    this.consumer = createConsumer(webSocketUrl);
  }

  subscribeToChannel(channelName, subscriberName, receivedCb) {
    return this.consumer.subscriptions.create(
      { channel: channelName, subscriber_name: subscriberName },
      {
        received: (data) => {
          if (receivedCb) {
            receivedCb(data);
          }
        },
      }
    );
  }
}

/*
 * This is a mock of the ActionCableService class that can be used for testing.
 *
class ActionCableService {
  subscribeToChannel(channelName, subscriberName, receivedCb) {
    console.log(`Subscribed to ${subscriberName}.`);
    const messages = [
      { ride_id: 2985, event_name: "not_started" },
      { ride_id: 3007, event_name: "not_started" },
      { "ride_id": 3023, "event_name": "created" },
      { "ride_id": 302111, "event_name": "created" },
    ];
    let index = 0;
    const intervalId = setInterval(() => {
      if (index >= messages.length) {
        clearInterval(intervalId);
        console.log(`Stopped subscription to ${subscriberName}.`);
        return;
      }
      const message = messages[index];
      console.log(
        `Message received from ${subscriberName}: ${JSON.stringify(message)}`
      );
      receivedCb(message);
      index++;
    }, Math.floor(Math.random() * 8000) + 3000);
  }
  unsubscribe() {
    console.log(`Unsubscribed from ${this.subscriberName}.`);
  }
}
*/

const actionCableService = new ActionCableService();

export default actionCableService;
