import FilterAlt from "@mui/icons-material/FilterAlt";
import { Badge, Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import { SecondaryButton } from "../../globalStyles/emotion";

export const TransportTypeFilter = ({
  transportTypeNumbers,
  transportTypes,
  openSelect,
  setOpenSelect,
  handleFilterTransportType,
}) => (
  <Select
    sx={{
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px ",
        padding: "0",
      },
      "& input": {
        padding: "0px",
      },
      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
        {
          padding: "0px",
        },
    }}
    style={{ fontSize: "15px", fontWeight: "600", padding: "0px" }}
    value={transportTypeNumbers}
    multiple
    displayEmpty
    open={openSelect}
    onOpen={() => setOpenSelect(true)}
    onClose={() => setOpenSelect(false)}
    onChange={handleFilterTransportType}
    className="transport-type-filter"
    renderValue={() => {
      return "";
    }}
    IconComponent={() => (
      <SecondaryButton variant="outlined" onClick={() => setOpenSelect(true)}>
        <FilterAlt style={{ fontSize: "16px" }} />
        <span style={{ marginLeft: "2px" }}>Filter</span>

        {transportTypeNumbers.length > 0 && (
          <div style={{ width: "20px", marginLeft: "5px" }}>
            <Badge
              badgeContent={transportTypeNumbers.length}
              color="secondary"
            />
          </div>
        )}
      </SecondaryButton>
    )}
    MenuProps={{
      variant: "menu",
    }}
  >
    <MenuItem value={0}>
      <Checkbox
        style={{
          color: "#572e72",
        }}
        checked={transportTypeNumbers.length === 0}
      />
      <ListItemText primary="All" />
    </MenuItem>
    {transportTypes.map((tt) => (
      <MenuItem key={tt.value} value={tt.value}>
        <Checkbox
          style={{
            color: "#572e72",
          }}
          checked={transportTypeNumbers.indexOf(tt.value) > -1}
        />
        <ListItemText primary={tt.name} />
      </MenuItem>
    ))}
  </Select>
);
