import axios from 'axios';
import { apiUrl, configWithUserId } from './headerConfigs';

const submitBestTimeBid = async ({ bidStartTime, rideId, driverId }) => {
  return axios
    .post(
      `${apiUrl}/Rides/SubmitBid`,
      {
        driver_id: driverId,
        ride_id: rideId,
        bid_start_time: bidStartTime,
      },
      configWithUserId
    )
}

export { submitBestTimeBid };