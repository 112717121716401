export class NotificationService {
  constructor() {
    if (this.isSupported()) {
      Notification.requestPermission().then((permission) => {
        this._permission = permission;
      });
    } else {
      console.warn("Browser notifications are not supported on this device.");
    }
  }

  set permission(permission) {
    this._permission = permission;
  }

  get permission() {
    return this._permission;
  }

  get granted() {
    return this._permission === "granted";
  }

  get denied() {
    return this._permission === "denied";
  }

  get permissionUnspecified() {
    if (this.isSupported()) {
      return !this.granted && !this.denied;
    }

    return false;
  }

  notify(title, body) {
    if (this.granted) {
      new Notification(title, { body });
    }
  }

  isSupported() {
    if ("Notification" in window) {
      return true;
    }

    return false;
  }
}
