import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";


const FirstAvailableTooltip = ({ status }) => {
  const title = status.toLowerCase() === "pending"
      ? "Once claimed, you will have an additional 30 minutes to begin the trip."
      : "This trip was claimed at the first available time.";

  return (
    <Tooltip enterDelay={500} title={title} placement="top">
      <IconButton disableRipple  style={{ padding: "4px" }}>
        <InfoOutlinedIcon style={{ fontSize: "small" }} />
      </IconButton>
    </Tooltip>
  );
};

export default FirstAvailableTooltip;
