import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./RideAlerts.css";

export function RideAlerts({ rides, onClick, onClose }) {
  return (
    rides && (
      <ul className="ride-alert-list">
        {rides.map((ride) => {
          return (
            <li className="ride-alert-item" key={ride.id}>
              <div className="ride-alert-item-text">
                <button
                  type="button"
                  className="ride-alert-button ride-alert-item-button"
                  onClick={() => {
                    onClick(ride);
                    onClose(ride);
                  }}
                >
                  <ErrorOutlineIcon className="ride-alert-exclaim-icon" />
                  <span>
                    Ride {ride.id} has not been started yet. Click to see ride.
                  </span>
                  <ArrowForwardIcon className="ride-alert-arrow-icon" />
                </button>
              </div>

              <button
                type="button"
                className="ride-alert-button ride-alert-close-button"
                aria-label="Close"
                onClick={() => onClose(ride)}
              >
                <CloseIcon />
              </button>
            </li>
          );
        })}
      </ul>
    )
  );
}
