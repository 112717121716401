import { makeStyles } from "@material-ui/core/styles";
import { timeZone } from "@onwardcare/core/utils/date-format";

const useStyles = makeStyles((theme) => ({
  timeZoneText: {
    fontSize: "small",
    color: theme.palette.text.hint,
  },
}));

export default function TimeZoneTimeText({ date }) {
  const classes = useStyles();

  return (
    <div className={classes.timeZoneText}>Time is in {timeZone(date)}</div>
  );
}
