export function formatPhoneNumber(str) {
  if (!str) return null;

  // Filter only numbers from the input
  const cleaned = str.replace(/\D/g, "");

  // Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
}
