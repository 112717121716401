import { ConfirmationDialog } from "../../components/dialogs";
import React, { useEffect } from "react";
import { Alert } from "@mui/material";
import { SecondaryButton, DisabledButton } from "../../globalStyles/emotion";
import TransportPartnerSection from "../sharedModalSections/TransportPartnerSection";
import DriverSection from "../sharedModalSections/DriverSection";
import BestTimeSelectionSection from "../sharedModalSections/BestTimeSelectionSection";
import { submitBestTimeBid } from "../../axiosHandlers/PendingDataSenders";
import handleLogout from "../auth/Logout";
import { mediumDateTime } from "@onwardcare/core/utils/date-format";

const BestTimeBidModal = ({
  rideId,
  setPartnerId,
  partnerId,
  transportPartnerData,
  transportTypeId,
  setSubmitBidMessage,
  setDriver,
  driverData,
  driverId,
  setDriverFirstName,
  setDriverLastName,
  disable,
  setDisable,
  closeButton,
  setSelectedBestTime,
  selectedBestTime,
  bestTimeOptions,
  biddingWindowEndsAt,
  error,
  setError,
  fetchBestTimeBids,
}) => {
  const modelBoxWidth = "400px";
  const dialogTitleStyles = {
    marginRight: "auto",
    borderBottom: "1px solid teal",
    paddingBottom: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: modelBoxWidth,
  };

  const biddingWindowEnds = mediumDateTime(biddingWindowEndsAt);
  const afterBidFlashMessage = `Thank you for your Best Time bid. Bidding ends at ${biddingWindowEnds}. You will be notified whether your bid was accepted.`;
  const bottomInfoMessage = `Onward will accept bids until ${biddingWindowEnds}. At that time we will assign the trip winning bidder.`;
  const topInfoMessage =
    "Onward supports “Best Time” bidding. If you would like to claim the ride but cannot commit to the requested time, choose an alternate time below.";

  useEffect(() => {
    if (driverId === "" || selectedBestTime === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [driverId, selectedBestTime, setDisable]);

  const handleTransportPartner = (id) => {
    setPartnerId(id);
    setDriver("");
  };

  const handleSubmitBestTimeBid = async (rideId, driverId, bidStartTime) => {
    setError(null);

    await submitBestTimeBid({
      bidStartTime: bidStartTime,
      rideId: rideId,
      driverId: driverId,
    })
      .then(() => {
        setSubmitBidMessage(afterBidFlashMessage);
        closeButton();
        fetchBestTimeBids(rideId);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handleLogout();
        } else if (error.response) {
          setError(`${error.response.data.detail}`);
        } else {
          setError("Something went wrong trying to submit the bid.");
        }
      });
  };

  return (
    <ConfirmationDialog open={true} onClose={closeButton}>
      <div style={dialogTitleStyles}>
        <ConfirmationDialog.Title>Best Time Bidding</ConfirmationDialog.Title>
      </div>
      <ConfirmationDialog.Body>
        {error ? (
          <Alert style={{ marginBottom: "10px" }} severity="error">
            {error}
          </Alert>
        ) : null}

        <div style={{ width: modelBoxWidth }}>
          <p>{topInfoMessage}</p>

          {localStorage.getItem("Page") === "Concierge" ? (
            <TransportPartnerSection
              partnerId={partnerId}
              handleTransportPartner={handleTransportPartner}
              setPartnerId={setPartnerId}
              transportTypeId={transportTypeId}
              transportPartnerData={transportPartnerData}
            />
          ) : null}
          <DriverSection
            driver={driverId}
            setDriver={setDriver}
            partnerId={partnerId}
            setDriverFirstName={setDriverFirstName}
            setDriverLastName={setDriverLastName}
            driverData={driverData}
          />
          <BestTimeSelectionSection
            bestTimeOptions={bestTimeOptions}
            selectedBestTime={selectedBestTime}
            setSelectedBestTime={setSelectedBestTime}
          />
          <p>{bottomInfoMessage}</p>
        </div>
      </ConfirmationDialog.Body>
      <ConfirmationDialog.Footer>
        <SecondaryButton variant="outlined" onClick={closeButton}>
          Cancel
        </SecondaryButton>
        <DisabledButton
          variant="contained"
          disabled={disable}
          onClick={() =>
            handleSubmitBestTimeBid(rideId, driverId, selectedBestTime)
          }
        >
          Submit Bid
        </DisabledButton>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};

export default BestTimeBidModal;
