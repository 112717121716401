import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export function DateSelect({ dateData, handleTime }) {
  return (
    <FormControl sx={{ width: "35%", gap: "50px" }}>
      <InputLabel id="demo-simple-select-label">Select Dates</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={dateData}
        label="Select Dates"
        onChange={handleTime}
      >
        <MenuItem value={0}>Today</MenuItem>
        <MenuItem value={1}>Last 7 Days</MenuItem>
        <MenuItem value={2}>This Month</MenuItem>
        <MenuItem value={3}>Last Month</MenuItem>
        <MenuItem value={4}>This Quarter</MenuItem>
      </Select>
    </FormControl>
  );
}