import {
  BadgeButton,
  BadgeFontSize,
  BadgeWhiteColor,
} from "../../globalStyles/emotion";

export function BadgeList({ children }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
      }}
    >
      {children}
    </div>
  );
}

function Badge({ id, details }) {
  return (
    <BadgeButton
      style={{ background: details.bgColor, color: details.textColor }}
      data-info={id}
      value={id}
    >
      <BadgeFontSize>{details.text}</BadgeFontSize>

      <BadgeWhiteColor style={{ color: details.textColor, boxShadow: "none" }}>
        {details.icon}
      </BadgeWhiteColor>
    </BadgeButton>
  );
}

BadgeList.Badge = Badge;
