const noDataMessage = "No data available.";
const defaultFileName = "rides.csv";

const exportSpecificColumns = {
  historical: [
    { label: "Tolls", key: "tolls" },
    { label: "Actual Miles", key: "actualMiles" },
    { label: "Cancelled Less Than 1 Hour", key: "cancelledLessThan1Hr" },
    { label: "Parking", key: "parking" },
  ],
  upcoming: [{ label: "Estimated Miles", key: "estimatedMiles" }],
};

const headers = [
  { label: "Ride Id", key: "rideId" },
  { label: "Date Of Service", key: "dateOfService" },
  { label: "Transport Type", key: "transportType" },
  { label: "Rider Id", key: "riderId" },
  { label: "Rider First Name", key: "riderFirstName" },
  { label: "Rider Last Name", key: "riderLastName" },
  { label: "DOB", key: "dob" },
  { label: "Rider Phone", key: "riderPhone" },
  { label: "Pickup Date and Time", key: "pickUpDateAndTime" },
  { label: "Pickup Location Name", key: "pickUpLocationName" },
  { label: "Pickup Location Address", key: "pickUpLocationAddress" },
  { label: "Drop Off Location Name", key: "dropOffLocationName" },
  { label: "Drop Off Location Address", key: "dropOffLocationAddress" },
  { label: "Financial Responsibility", key: "financialResponsibility" },
  { label: "Cost Center", key: "costCenter" },
  { label: "Ride Status", key: "rideStatus" },
  { label: "Stairs Charge", key: "stairsCharge" },
  { label: "Bariatric", key: "bariatric" },
  { label: "Oxygen", key: "oxygenRequired" },
  { label: "Droplet Precautions", key: "contactPrecautions" },
  {
    label: "Transport to provide wheelchair",
    key: "riderWillUseProvidersWheelchair",
  },
  { label: "Account Id", key: "accountId" },
  { label: "Account Name", key: "accountName" },
  { label: "Driver Id", key: "driverId" },
  { label: "Driver Name", key: "driverName" },
  { label: "Transport Partner Id", key: "transportPartnerId" },
  { label: "Transport Partner Name", key: "transportPartnerName" },
  { label: "Ride Notes", key: "rideNotes" },
  { label: "Ride Payment Paid At", key: "ridePaymentPaidAt" },
  { label: "Payment Amount", key: "paymentAmount" },
  { label: "Payment Stripe Id", key: "paymentStripeId" },
  { label: "Payment Status", key: "paymentStatus" },
  { label: "Partner Approval", key: "partnerApproval" },
  { label: "Additional Partner Notes", key: "additionalPartnerNotes" },
];

const pageConfig = (exportData) => {
  const pageData = {
    historical: {
      title: "Export a list of rides completed by your drivers",
      file: defaultFileName,
      headers: headers.concat(exportSpecificColumns.historical),
    },
    upcoming: {
      title: "Export a list of upcoming rides for your drivers",
      file: `upcoming_${defaultFileName}`,
      headers: headers.concat(exportSpecificColumns.upcoming),
    },
  };

  return pageData[exportData];
};

export { noDataMessage, pageConfig };
