/*global google*/
import React, { useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useState } from "react";
import { IconButton } from "@mui/material";
const Map = ({
  startLocationLatitude,
  startLocationLongitude,
  endLocationLatitude,
  endLocationLongitude,
}) => {
  const [selectMap, setSelectMap] = useState(/** @type google.maps.Map */ null);
  const [directionResponse, setDirectionReseponse] = useState();
  const [libraries] = useState(["places"]);
  const center = { lat: startLocationLatitude, lng: startLocationLongitude };
  const { isLoaded } = useJsApiLoader({
    libraries,
  });

  async function calculateRoute() {
    let directionsService = "";
    let results = "";

    if (startLocationLatitude) {
      // eslint-disable-next-line no-undef
      directionsService = new google.maps.DirectionsService();
      results = await directionsService.route({
        origin: { lat: startLocationLatitude, lng: startLocationLongitude },
        destination: { lat: endLocationLatitude, lng: endLocationLongitude },
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirectionReseponse(results);
    } else {
      return;
    }
  }

  useEffect(() => {
    calculateRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isLoaded ? (
        <div>Loading</div>
      ) : (
        <div
          style={{
            height: "100%",
            width: "350px",
            padding: "10px",
            margin: "auto",
            position: "relative",
          }}
        >
          <GoogleMap
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            onLoad={(selectMap) => setSelectMap(selectMap)}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
            }}
          >
            <DirectionsRenderer directions={directionResponse} />
          </GoogleMap>
          <IconButton
            style={{ position: "absolute", bottom: "35px", right: "20px" }}
            onClick={() => selectMap.panTo(center)}
          >
            <NearMeIcon />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default Map;
