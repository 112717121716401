/* eslint-disable eqeqeq */
import React from "react";
import Duration from "../duration/Duration";
import DashTimeFormat from "../time/DashTimeFormat";
import SidePanelDetails from "../sidePanelDetails/SidePanelDetails";

const SidePanel = ({ rowId, data }) => {
  return (
    <div style={{ flex: 1 }}>
      {data.map(
        ({
          vehicleSummary,
          custodianDisplayName,
          custodianPhone,
          custodianEmail,
          driverId,
          driverDisplayName,
          driverPhone,
          driverEmail,
          startLocationAddress,
          endLocationAddress,
          isBariatric,
          isInsurance,
          suppressPayment,
          riderDisplayName,
          riderPhone,
          estimatedPayout,
          bonus,
          actualTotal,
          estimatedTotal,
          startLocationNotes,
          endLocationNotes,
          startLocationName,
          endLocationName,
          id,
          rideNotes,
          hasStairs,
          rideType,
          transportTypeId,
          moveToRide,
          riderEmail,
          riderNumber,
          riderNotes,
          riderWeightDisplay,
          riderHeightDisplay,
          oxygenRequired,
          contactPrecautionsRequired,
          estimatedDurationMinutes,
          estimatedDistanceMiles,
          requestedStartTime,
          requestedEndTime,
          startLocationLatitude,
          startLocationLongitude,
          endLocationLatitude,
          endLocationLongitude,
          riderWillUseProvidersWheelchair,
          status,
          firstAvailable,
          payerName,
          payerType,
          costCenter,
          startLocationRoomNumber,
          endLocationRoomNumber,
          bedsideNurseFullName,
          bedsideNursePhone,
          isLongDistance,
          riderDob,
          carePartnerDisplayName,
          carePartnerPhone,
          carePartnerRelationshipType,
          carePartnerRelationshipNotes,
          appointmentTime,
          transportReasonName,
          transportReasonCategoryName,
          transportReasonComment,
        }) => (
          <React.Fragment key={id}>
            {id == rowId ? (
              <>
                <div
                  style={{
                    background: "rgba(0,128,128,0.2)",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    height: "70px",
                    display: "flex",
                    alignItems: "center",
                    borderTopRightRadius: "25px",
                  }}
                >
                  <DashTimeFormat
                    requestedStartTime={requestedStartTime}
                    requestedEndTime={requestedEndTime}
                  />
                </div>
                <SidePanelDetails
                  id={id}
                  transportTypeId={transportTypeId}
                  rideType={rideType}
                  custodianDisplayName={custodianDisplayName}
                  riderDisplayName={riderDisplayName}
                  hasStairs={hasStairs}
                  isBariatric={isBariatric}
                  isInsurance={isInsurance}
                  suppressPayment={suppressPayment}
                  startLocationName={startLocationName}
                  startLocationAddress={startLocationAddress}
                  startLocationNotes={startLocationNotes}
                  endLocationNotes={endLocationNotes}
                  endLocationAddress={endLocationAddress}
                  startLocationLatitude={startLocationLatitude}
                  startLocationLongitude={startLocationLongitude}
                  Duration={Duration}
                  estimatedDurationMinutes={estimatedDurationMinutes}
                  estimatedDistanceMiles={estimatedDistanceMiles}
                  requestedStartTime={requestedStartTime}
                  requestedEndTime={requestedEndTime}
                  vehicleSummary={vehicleSummary}
                  estimatedPayout={estimatedPayout}
                  bonus={bonus}
                  riderWillUseProvidersWheelchair={
                    riderWillUseProvidersWheelchair
                  }
                  rideNotes={rideNotes}
                  riderNumber={riderNumber}
                  endLocationName={endLocationName}
                  actualTotal={actualTotal}
                  estimatedTotal={estimatedTotal}
                  riderWeightDisplay={riderWeightDisplay}
                  riderHeightDisplay={riderHeightDisplay}
                  riderNotes={riderNotes}
                  endLocationLatitude={endLocationLatitude}
                  endLocationLongitude={endLocationLongitude}
                  driverDisplayName={driverDisplayName}
                  driverId={driverId}
                  status={status}
                  firstAvailable={firstAvailable}
                  custodianPhone={custodianPhone}
                  custodianEmail={custodianEmail}
                  driverEmail={driverEmail}
                  driverPhone={driverPhone}
                  riderEmail={riderEmail}
                  riderPhone={riderPhone}
                  payerName={payerName}
                  payerType={payerType}
                  costCenter={costCenter}
                  startLocationRoomNumber={startLocationRoomNumber}
                  endLocationRoomNumber={endLocationRoomNumber}
                  bedsideNurseFullName={bedsideNurseFullName}
                  bedsideNursePhone={bedsideNursePhone}
                  isLongDistance={isLongDistance}
                  riderDob={riderDob}
                  carePartnerDisplayName={carePartnerDisplayName}
                  carePartnerPhone={carePartnerPhone}
                  carePartnerRelationshipType={carePartnerRelationshipType}
                  carePartnerRelationshipNotes={carePartnerRelationshipNotes}
                  appointmentTime={appointmentTime}
                  transportReasonName={transportReasonName}
                  transportReasonCategoryName={transportReasonCategoryName}
                  transportReasonComment={transportReasonComment}
                />
              </>
            ) : null}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default SidePanel;
