import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import "./Navbar.css";
import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../context/Context";
import { Avatar, Menu, MenuItem } from "@mui/material";
import Logo from "../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import handleLogout from "../auth/Logout";

export default function Navbar() {
  const [value] = useContext(AdminContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fullName, setFullName] = useState("");

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setFullName(getFullName);
  }, []);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    let initial;
    if (name.split(" ").length === 3) {
      initial = `${fullName.split(" ")[0][0]}${
        name.toString().split(" ")[2][0]
      }`;
    } else {
      initial = `${fullName.split(" ")[0][0]}${
        name.toString().split(" ")[1][0]
      }`;
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initial,
    };
  }

  let getFullName =
    localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");

  return (
    <div style={{ width: "100%", position: "fixed", zIndex: "50" }}>
      {value != null ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            style={{ background: "white", borderBottom: "1px solid teal" }}
          >
            <Toolbar>
              <Typography
                style={{ color: "black", fontWeight: 250 }}
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Onward"
                    style={{ maxWidth: "140px", marginTop: "5px" }}
                  />
                </Link>
              </Typography>
              {/* <div style={{ display: "flex", gap: "50px" }}>


                  <h1 style={{ color: "	#585858", fontWeight: "700", letterSpacing: "1px", paddingBottom: "5px" }} >  {localStorage.getItem('Page')}     </h1>

                  <h1 style={{ color: "	#585858", marginRight: "90px", fontWeight: "700", letterSpacing: "1px", }} > Dashboard   </h1>
                </div> */}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                style={{ color: "black" }}
              >
                <Avatar
                  style={{ fontSize: "14px", height: "35px", width: "35px" }}
                  {...stringAvatar(getFullName)}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem style={{ color: "grey" }}>
                  {" "}
                  {localStorage.getItem("Page")}{" "}
                </MenuItem>
                <MenuItem style={{ color: "grey" }}>
                  {" "}
                  {localStorage.getItem("firstName")}{" "}
                  {localStorage.getItem("lastName")}{" "}
                </MenuItem>
                <Link to="/downloadHistoricalRides" style={{ textDecoration: "none" }}>
                  <MenuItem> Download Historical Rides </MenuItem>
                </Link>
                <Link to="/downloadUpcomingRides" style={{ textDecoration: "none" }}>
                  <MenuItem> Download Upcoming Rides </MenuItem>
                </Link>

                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
      ) : null}
    </div>
  );
}
