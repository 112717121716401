import { Button, TextField, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./auth.css";

const Register = () => {
  return (
    <div className="auth-form-wrapper">
      <form className="auto-form-container">
        <h3 className="auth-logo">ONWARD</h3>
        <h4 className="auth-title">Sign In</h4>
        <TextField id="outlined" label="First Name" type="text" />
        <TextField id="outlined" label="Last Name" type="text" />
        <TextField id="outlined" label="Phone" type="text" />
        <TextField id="outlined" label="Company Name" type="text" />
        <TextField id="outlined" label="Email" type="text" />

        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
        />
        <TextField
          id="outlined-password-input"
          label="Confirm Password"
          type="password"
          autoComplete="current-password"
        />
        <Button
          style={{ width: "100%", marginTop: "20px", background: "#215261" }}
          variant="contained"
        >
          SIGN IN
        </Button>
        <div className="auto-form-text-link-container">
          <p className="forgot-password">Forgot Password?</p>
          <Link to="/login">
            <p className="have-account">Have an Account?</p>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Register;
