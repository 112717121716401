import { Alert, InputLabel, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
  BadgeWhiteColor,
  DisabledButton,
  SecondaryButton,
} from "../../globalStyles/emotion";
import { ConfirmationDialog } from "../../components/dialogs";
import TransportPartnerSection from "../sharedModalSections/TransportPartnerSection";
import DriverSection from "../sharedModalSections/DriverSection";

const ClaimRideCard = ({
  isBariatric,
  isInsurance,
  hasStairs,
  transportTypeId,
  partnerId,
  setPartnerId,
  setDisable,
  disable,
  transportPartnerData,
  driverData,
  setDriverNotes,
  closeButton,
  setOpen,
  setDriver,
  driver,
  setDriverFirstName,
  setDriverLastName,
}) => {
  const handleTransportPartner = (id) => {
    setPartnerId(id);
    setDriver("");
  };

  const handleNotes = (event) => {
    setDriverNotes(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (driver === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [driver]);

  return (
    <ConfirmationDialog open={true}>
      <ConfirmationDialog.Title>
        <div
          style={{
            marginRight: "auto",
            borderBottom: "1px solid teal",
            paddingBottom: "10px",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Claim Ride
          <div style={{ display: "flex", gap: "10px" }}>
            {isBariatric !== false ? (
              <BadgeWhiteColor>{isBariatric.icon}</BadgeWhiteColor>
            ) : null}

            {hasStairs !== false ? (
              <BadgeWhiteColor>{hasStairs.icon}</BadgeWhiteColor>
            ) : null}

            <div
              style={{
                borderRadius: "4px",
                border: "1px solid green",
                boxShadow: "1px 1px grey,  0  1px 1px  green",
                fontSize: "25px",
                color: "grey",
                padding: "2px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {transportTypeId.icon}
            </div>
          </div>
        </div>
      </ConfirmationDialog.Title>

      <ConfirmationDialog.Body>
        <div style={{ width: "300px" }}>
          {isInsurance && (
            <Alert severity="warning">
              By claiming this ride, you are agreeing to bill the rider's
              insurance.
            </Alert>
          )}
          <div style={{ color: "red" }}>
            <p>{isBariatric.message}</p>
          </div>
          {localStorage.getItem("Page") === "Concierge" ? (
            <TransportPartnerSection
              partnerId={partnerId}
              handleTransportPartner={handleTransportPartner}
              setPartnerId={setPartnerId}
              transportTypeId={transportTypeId}
              transportPartnerData={transportPartnerData}
            />
          ) : null}
          <DriverSection
            driver={driver}
            setDriver={setDriver}
            partnerId={partnerId}
            setDriverFirstName={setDriverFirstName}
            setDriverLastName={setDriverLastName}
            driverData={driverData}
          />
          {localStorage.getItem("Page") === "Dispatch" ? null : (
            <>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginRight: "auto" }}
              >
                Note
              </InputLabel>
              <TextField
                variant="outlined"
                onChange={handleNotes}
                style={{ width: "100%" }}
              />
            </>
          )}
        </div>
      </ConfirmationDialog.Body>

      <ConfirmationDialog.Footer>
        <SecondaryButton variant="outlined" onClick={closeButton}>
          Cancel
        </SecondaryButton>

        <DisabledButton
          variant="contained"
          disabled={disable}
          onClick={handleClickOpen}
        >
          Claim Ride
        </DisabledButton>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};

export default ClaimRideCard;
