import { 
  Box, 
  InputLabel, 
  MenuItem, 
  FormControl, 
  Select, 
  Button, 
  Card, 
  Typography, 
  Alert, 
  IconButton 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadCsvHooks from "./DownloadCsvHooks";
import { CSVLink } from "react-csv";
import { DateSelect } from './DateSelect';

const DownloadCsv = ({exportData}) => {
  const {
    handleTime,
    handlePartner,
    handleExportRequest,
    pageData,
    partnerId,
    dateData,
    partnerList,
    isLoading,
    fetchedData,
    csvLink,
    noDataAlert,
    setNoDataAlert,
  } = DownloadCsvHooks(exportData);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        style={{
          background: "white",
          width: "50vw",
          height: "70vh",
          borderRadius: "25px",
        }}
      >
        <Typography style={{ padding: "50px" }} variant="h5">
          {pageData.title}
        </Typography>
        <Box
          style={{
            marginLeft: "50px",
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          {localStorage.getItem("Page") === "Concierge" ? (
            <FormControl sx={{ width: "35%" }}>
              <InputLabel>Partner</InputLabel>
              <Select
                value={partnerId}
                label="Partner"
                onChange={handlePartner}
              >
                {partnerList?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label ?? option.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : null}
          {exportData === "historical" ? (
            <DateSelect dateData={dateData} handleTime={handleTime} />
          ) : null}
          <Button
            onClick={handleExportRequest}
            sx={{ width: "270px", marginLeft: "5px" }}
            variant="contained"
          >
            {isLoading === true ? "Downloading..." : "Export"}{" "}
          </Button>

          {noDataAlert && (
            <Alert 
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setNoDataAlert(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              variant="outlined"
              severity="warning"
              style={{
                right: "50px",
                zIndex: 999,
                width: "300px",
                position: "absolute",
                background: "white",
                top: "100px",
                borderTop: "grey",
                borderRight: "grey",
                borderBottom: "grey",
                boxShadow: "3px 3px 3px  grey",
              }}
            >{noDataAlert}</Alert>
          )}

          <CSVLink
            headers={pageData.headers}
            data={fetchedData}
            filename={pageData.file}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </Box>
      </Card>
    </div>
  );
};

export default DownloadCsv;
