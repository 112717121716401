import { IconButton, Paper, Tooltip } from "@mui/material";
import personStyles from "./styles.module.css";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import React from "react";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

export const ContactsPopper = ({
  personEmail,
  personPhone,
  shouldShowActions,
  personName,
}) => {
  return (
    <Paper elevation={1} style={{ backgroundColor: "white", padding: "1em" }}>
      {!!personEmail && (
        <div style={{ display: "flex" }}>
          <div>
            <span style={{ fontWeight: 700, marginRight: "1em" }}>Email:</span>
            <span style={{ marginRight: "1em" }}>{personEmail}</span>
          </div>
          <Tooltip
            enterDelay={500}
            title={"Copy email to clipboard"}
            placement="top"
          >
            <IconButton
              onClick={() => navigator.clipboard.writeText(personEmail)}
              size="small"
              aria-label="copy"
              className={personStyles.icons}
            >
              <FileCopyOutlined className={personStyles.icons} />
            </IconButton>
          </Tooltip>
          {shouldShowActions && (
            <Tooltip
              enterDelay={500}
              title={`Email to ${personName}`}
              placement="top"
            >
              <IconButton
                size="small"
                aria-label="email"
                className={personStyles.icons}
              >
                <a href={`mailto:${personEmail}`}>
                  <MailOutlineIcon className={personStyles.icons} />
                </a>
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
      {!!personPhone && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <span style={{ fontWeight: 700, marginRight: "1em" }}>Phone:</span>
            <span style={{ marginRight: "1em" }}>
              {formatPhoneNumber(personPhone)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              enterDelay={500}
              title={"Copy phone to clipboard"}
              placement="top"
            >
              <IconButton
                onClick={() => navigator.clipboard.writeText(personPhone)}
                size="small"
                aria-label="copy"
                className={personStyles.icons}
              >
                <FileCopyOutlined className={personStyles.icons} />
              </IconButton>
            </Tooltip>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {shouldShowActions && (
                <Tooltip
                  enterDelay={500}
                  title={`Call ${personPhone}`}
                  placement="top"
                >
                  <IconButton
                    disableFocusRipple
                    size="small"
                    aria-label="phoneCall"
                    className={personStyles.icons}
                  >
                    <a href={`tel:${personPhone}`}>
                      <PhoneIcon className={personStyles.icons} />
                    </a>
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      )}
    </Paper>
  );
};
