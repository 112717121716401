import { useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@mui/icons-material/Clear";

const Search = ({ query, setQuery }) => {
  const [debouncedTerm, setDebouncedTerm] = useState(query);

  useEffect(
    () => {
      // If the query has changed, it's because it was set from a parent
      // component. In that case, we need to set the debouncedTerm so that
      // the search field will display the value.
      if (debouncedTerm !== query) {
        setDebouncedTerm(query);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [query /* we purposely don't want to have debouncedTerm as a dependency */]
  );

  useEffect(() => {
    const timer = setTimeout(() => setQuery(debouncedTerm), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm, setQuery]);

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <TextField
        size="small"
        value={debouncedTerm}
        variant="outlined"
        sx={{
          div: { background: "white", border: 0 },
          "& input::placeholder": { fontSize: "11px" },
        }}
        placeholder="Search By First Name or Ride ID..."
        InputProps={{
          endAdornment: (
            <InputAdornment size="small" position="end">
              {query === "" ? (
                <IconButton size="small">
                  <SearchIcon size="small" />{" "}
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  onClick={() => {
                    setDebouncedTerm("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        onChange={(e) => setDebouncedTerm(e.target.value)}
      />
    </div>
  );
};

export default Search;
