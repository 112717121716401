import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";  

const TransportPartnerSection = ({
  partnerId,
  handleTransportPartner,
  setPartnerId,
  transportTypeId,
  transportPartnerData,
}) => {
  return (<>
    <div style={{ marginRight: "auto", marginBottom: "5px", marginTop: "15px" }}>
      <h1>Transport Partner</h1>
    </div>
    <FormControl fullWidth style={{ marginBottom: "20px" }}>
      {partnerId === -1 ? (
        <InputLabel id="demo-simple-select-label">All</InputLabel>
      ) : (
        <InputLabel id="demo-simple-select-label">Ride</InputLabel>
      )}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Driver"
        value={partnerId}
        onChange={(event) => {
          setPartnerId(event.target.value);
        }}
      >
        <MenuItem value={-1}>All</MenuItem>
        {transportPartnerData
          .find((p) => p.transportTypeId === transportTypeId)
          ?.transportPartners.map(({ name, id }) => (
            <MenuItem
              key={id}
              onClick={(event, name) =>
                handleTransportPartner(event, id, name)
              }
              value={id}
            >
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  </>)
}

export default TransportPartnerSection;