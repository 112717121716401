import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AdminProvider } from "./components/context/Context";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

  <ThemeProvider theme={theme}>
    <AdminProvider>
      <App />
    </AdminProvider>
  </ThemeProvider>
  // </React.StrictMode> */}
);
