import React, { Fragment } from "react";
import personStyles from "./styles.module.css";
import { IconButton } from "@mui/material";
import { FontWeight } from "../../globalStyles/emotion";
import ContactsIcon from "@mui/icons-material/Contacts";
import Popper from "@mui/material/Popper";
import { ClickAwayListener } from "@mui/base";
import { Box } from "@mui/system";
import { ContactsPopper } from "./contactsPopper";

export const PersonDetails = ({ persons = [], shouldShowActions = false }) => {
  const isAdmin = localStorage.getItem("admin") === "true";

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const id = open ? "simple-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      {persons.map(
        ({ personName, personId, personLink, personEmail, personPhone }) => {
          const key = personId || personName;

          return (
            <Fragment key={key}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FontWeight>{personName}</FontWeight>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {isAdmin && shouldShowActions && personLink && personId && (
                    <FontWeight>
                      <a href={personLink} target="_blank" rel="noreferrer">
                        {personId}
                      </a>
                    </FontWeight>
                  )}

                  {personEmail || personPhone ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box sx={{ position: "relative" }}>
                        <IconButton
                          onClick={handleClick}
                          size="large"
                          aria-label="copy"
                          className={personStyles.icons}
                        >
                          <ContactsIcon />
                        </IconButton>
                        <Popper id={id} open={open} anchorEl={anchorEl}>
                          <ContactsPopper
                            personEmail={personEmail}
                            personPhone={personPhone}
                            shouldShowActions={shouldShowActions}
                            personName={personName}
                          />
                        </Popper>
                      </Box>
                    </ClickAwayListener>
                  ) : null}
                </div>
              </div>
            </Fragment>
          );
        }
      )}
    </>
  );
};
