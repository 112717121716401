import React from "react";
import { Box } from "@mui/material";
import BidsTable from "./BidsTable";
import { timeOnly } from "@onwardcare/core/utils/date-format";

const BestTimeBidBoard = ({
  biddingWindowEndsAt,
  requestedStartTime,
  bestTimeBids,
}) => {
  const styles = {
    mainContainer: {
      borderTop: "1px solid lightgrey",
      padding: "20px",
    },
    headingsContainer: {
      marginBottom: "20px",
    },
    paragraph: {
      fontSize: "15px",
    },
    span: {
      fontWeight: 600,
    },
  };

  return (
    <div style={styles.mainContainer}>
      <Box sx={styles.headingsContainer}>
        <h2>Best Time Bids</h2>
        <p style={styles.paragraph}>
          Requested Start Time:{" "}
          <span style={styles.span}>{timeOnly(requestedStartTime)}</span>
        </p>
        <p style={styles.paragraph}>
          Bidding Window End:{" "}
          <span style={styles.span}>{timeOnly(biddingWindowEndsAt)}</span>
        </p>
      </Box>
      <BidsTable bestTimeBids={bestTimeBids} />
    </div>
  );
};

export default BestTimeBidBoard;
