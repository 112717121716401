import React from "react";
import { FontWeight } from "../../globalStyles/emotion";

const Duration = ({ estimatedDistanceMiles, estimatedDurationMinutes }) => {
  estimatedDurationMinutes = (estimatedDurationMinutes / 60).toFixed(2);

  return (
    <>
      {" "}
      <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
        <p style={{ width: "62px" }}>Duration:</p>
        <FontWeight style={{ fontWeight: "800" }}>
          {" "}
          {estimatedDurationMinutes}
          {estimatedDurationMinutes <= 1 ? (
            <span> hour</span>
          ) : (
            <span> hours</span>
          )}
        </FontWeight>
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        <p style={{ width: "62px" }}>Distance:</p>
        <FontWeight>
          {estimatedDistanceMiles}
          {estimatedDistanceMiles == 1 ? (
            <span> mile</span>
          ) : (
            <span> miles</span>
          )}
        </FontWeight>
      </div>
    </>
  );
};

export default Duration;
