import { Alert } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import ENV from "../../constants/Environment";
import { DisabledButton, SecondaryButton } from "../../globalStyles/emotion";
import { ConfirmationDialog } from "../../components/dialogs";
import handleLogout from "../auth/Logout";

const { apiUrl } = ENV;

const StartRideCard = ({
  rideId,
  onClose,
  setClaimRideMessage,
  setReloadData,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [isValidationError, setIsValidationError] = useState(false);

  const handleStartRide = (rideId) => {
    setError(null);
    setIsValidationError(false);
    setDisabled(true);

    axios
      .post(
        `${apiUrl}/Rides/Start`,
        {
          ride_id: rideId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "User-Id": localStorage.getItem("userid"),
          },
        }
      )
      .then(() => {
        setError(null);
        onClose();
        setReloadData(true);
        setClaimRideMessage("Ride has been started");
      })
      .then(() => {
        setInterval(() => {
          setReloadData(false);
        }, 2000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handleLogout();
        } else if (error.response.status === 422) {
          setIsValidationError(true);
          setError(error.response.data.detail);
        } else if (error.response.data.detail) {
          setError(error.response.data.detail);
        } else {
          setError("Something went wrong trying to start the ride.");
        }

        setDisabled(false);
      })
      .then(() => {
        if (error) {
          // Don't do anything if there is an error rendered.
          return;
        }

        setInterval(() => {
          setDisabled(false);
        }, 2000);
      });
  };

  return (
    <ConfirmationDialog open={rideId}>
      <ConfirmationDialog.Title>Start Ride</ConfirmationDialog.Title>

      <ConfirmationDialog.Body>
        {error ? (
          <Alert style={{ marginBottom: "10px" }} severity="error">
            {error}
          </Alert>
        ) : null}

        {isValidationError === false && (
          <p>Are you sure you want to start this Ride?</p>
        )}
      </ConfirmationDialog.Body>

      <ConfirmationDialog.Footer>
        <SecondaryButton variant="outlined" onClick={onClose}>
          {isValidationError ? "Close" : "No"}
        </SecondaryButton>

        {isValidationError === false && (
          <DisabledButton
            variant="contained"
            disabled={disabled}
            onClick={() => handleStartRide(rideId)}
          >
            Yes
          </DisabledButton>
        )}
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};

export default StartRideCard;
