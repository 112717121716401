import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ENV from "../../constants/Environment";

const useStyles = makeStyles((theme) => ({
  showButton: {
    fontSize: "0.75rem",
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.info.main,
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
    "&:hover": {
      color: theme.palette.info.dark,
    },
    "&:focus": {
      color: theme.palette.info.dark,
    },
  },
  photo: {
    width: "100%",
    height: "100%",
  },
}));

export const LocationPhoto = ({ locationPhoto }) => {
  const classes = useStyles();
  const [showPhoto, setShowPhoto] = useState(false);

  const handlePhotoClose = () => {
    setShowPhoto(false);
  };

  return (
    <div>
      <button
        type="button"
        className={classes.showButton}
        onClick={() => setShowPhoto(true)}
      >
        Show Location Photo
      </button>

      <Dialog
        open={showPhoto}
        fullWidth={true}
        maxWidth="sm"
        keepMounted
        onClose={handlePhotoClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={ENV.onwardApiUrl + locationPhoto}
              alt=""
              className={classes.photo}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePhotoClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
