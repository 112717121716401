import { useEffect } from "react";
import ENV from "../../constants/Environment";

export const useZendesk = (userId) => {
  useEffect(() => {
    function fetchToken(callback_fetch = () => {}) {
      const options = {
        method: "POST",
        headers: {},
      };

      if (userId && userId !== "") {
        fetch(
          `${ENV.onwardApiUrl}/api/custodians/${userId}/zendesk/tokens`,
          options
        )
          .then((response) => response)
          .then((response) => response.text())
          .then((text) => {
            callback_fetch(text);
            zE("messenger:set", "cookies", true);
          })
          .catch((e) => console.log("ERROR", e));
      } else {
        return "invalid userId";
      }
    }

    function loginUser() {
      zE("messenger", "loginUser", function (callback) {
        fetchToken(function (token) {
          callback(token);
        });
      });
    }

    loginUser();

    return () => {
      // Will hide the zendesk chat
      zE("messenger:set", "cookies", false);
    };
  }, [userId]);
};
