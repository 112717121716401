import AccessibleIcon from "@mui/icons-material/Accessible";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AirlineSeatFlatIcon from "@mui/icons-material/AirlineSeatFlat";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LocalHospital from "@mui/icons-material/LocalHospital";
import ModeOfTravelOutlinedIcon from "@mui/icons-material/ModeOfTravelOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import StairsIcon from "@mui/icons-material/Stairs";

const ALS_AMBULANCE = {
  text: "ALS Ambulance",
  icon: <LocalHospital style={{ fontSize: "16px" }} />,
  bgColor: "#54174C",
  textColor: "white",
};

const BARIATRIC = {
  text: "Bariatric",
  icon: <MonitorWeightIcon style={{ fontSize: "14px" }} />,
  bgColor: "#ff9966",
  textColor: "white",
};

const BLS_AMBULANCE = {
  text: "BLS Ambulance",
  icon: <LocalHospital style={{ fontSize: "16px" }} />,
  bgColor: "#8260a2",
  textColor: "white",
};

const CCT_AMBULANCE = {
  text: "CCT Ambulance",
  icon: <LocalHospital style={{ fontSize: "16px" }} />,
  bgColor: "#572E72",
  textColor: "white",
};

const COMPANION = {
  text: "Companion",
  icon: <PeopleAltIcon style={{ fontSize: "16px" }} />,
  bgColor: "#572e72",
  textColor: "white",
};

const DELIVERY = {
  text: "Delivery",
  icon: <DeliveryDiningIcon style={{ fontSize: "14px" }} />,
  bgColor: "#572e72",
  textColor: "white",
};

const GURNEY = {
  text: "Gurney",
  icon: <AirlineSeatFlatIcon style={{ fontSize: "16px" }} />,
  bgColor: "#2f193b",
  textColor: "white",
};

const INSURANCE = {
  text: "Insurance",
  icon: <AccountBalanceIcon style={{ fontSize: "14px" }} />,
  bgColor: "#b69a74",
  textColor: "white",
};

const LONG_DISTANCE = {
  text: "Long Distance",
  icon: <MonetizationOnIcon style={{ fontSize: "14px" }} />,
  bgColor: "#171717",
  textColor: "white",
};

const ONE_WAY = {
  text: "One Way",
  icon: <ArrowCircleRightIcon style={{ fontSize: "14px" }} />,
  bgColor: "#572e72",
  textColor: "white",
};

const RIDESHARE = {
  text: "Rideshare",
  icon: <DriveEtaIcon style={{ fontSize: "16px" }} />,
  bgColor: "#6D7679",
  textColor: "white",
};

const STAIRS = {
  text: "Stairs",
  icon: <StairsIcon style={{ fontSize: "14px" }} />,
  bgColor: "teal",
  textColor: "white",
};

const WAIT_AND_RETURN = {
  text: "Wait & Return",
  icon: <ModeOfTravelOutlinedIcon style={{ fontSize: "14px" }} />,
  bgColor: "#572e72",
  textColor: "white",
};

const WHEELCHAIR = {
  text: "Wheelchair",
  icon: <AccessibleIcon style={{ fontSize: "16px" }} />,
  bgColor: "#92c9d6",
  textColor: "white",
};

export const BADGE_TYPES = {
  ALS_AMBULANCE,
  BARIATRIC,
  BLS_AMBULANCE,
  CCT_AMBULANCE,
  COMPANION,
  DELIVERY,
  GURNEY,
  INSURANCE,
  LONG_DISTANCE,
  ONE_WAY,
  RIDESHARE,
  STAIRS,
  WAIT_AND_RETURN,
  WHEELCHAIR,
};

export function badgeTypeByTransportTypeId(transportTypeId) {
  switch (transportTypeId) {
    case 1:
      return COMPANION;

    case 2:
      return RIDESHARE;

    case 3:
      return GURNEY;

    case 4:
      return WHEELCHAIR;

    case 5:
      return BLS_AMBULANCE;

    case 6:
      return CCT_AMBULANCE;

    case 7:
      return ALS_AMBULANCE;

    default:
      return {
        text: "Unknown",
        bgColor: "#572e72",
        textColor: "white",
      };
  }
}

export function badgeTypeByRideType(rideType) {
  switch (rideType) {
    case "roundtrip":
      return WAIT_AND_RETURN;

    case "delivery":
      return DELIVERY;

    case "oneway":
      return ONE_WAY;

    default:
      return {
        text: rideType,
        bgColor: "#572e72",
        textColor: "white",
      };
  }
}
