import styled from "@emotion/styled";
import {
  Badge,
  Button,
  DialogContent,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

export const TextTransformButton = styled(Button)`
  text-transform: none;
  cursor: default;
  font-weight: 600;
  hover: none;
  color: black;
`;

export const BadgeButton = styled.button`
  justify-content: center;
  border: 0;
  user-select: none;
  vertical-align: middle;
  font-weight: 500;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  display: flex;
  align-items: center;
  text-align: center;
  height: 25px;

  cursor: default;
  padding: 10px;
  border-radius: 25px;
  background: ${(props) => props.color ?? "#572e72"};

  @media only screen and (max-width: 1200px) {
    font-size: 5px;
    min-width: 60px;
  }
`;

export const BadgeWhiteColor = styled(Badge)`
  box-shadow: 1px 1px grey, 0 1px 1px green;
  color: grey;
  padding: 2px;
  display: flex;
  alignitems: center;
  justifycontent: center;
  cursor: pointer;
  border-radius: 4px;
`;

export const TransportIcon = styled.div`
  border-radius: 4px;
  border: 1px solid green;
  box-shadow: 1px 1px grey, 0 1px 1px green;
  color: grey;
  padding: 2px;
  display: flex;
  alignitems: center;
  justifycontent: center;
`;

export const FontWeight = styled.p`
  font-weight: 600;
  @media only screen and (max-width: 1200px) {
    font-size: 13px;
  }
`;

export const DisabledButton = styled(Button)`
  text-transform: none;
  background: #572e72;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    background: #2f193b;
  }
  &.Mui-disabled {
    color: #fff;
  }
`;

export const SecondaryButton = styled(Button)`
  text-transform: none;
  font-size: 12px;
  border: 1px solid #572e72;
  color: #2f193b;
  background-color: white;
  &:hover {
    background-color: #e6dfec;
    border: 1px solid #572e72;
    color: #2f193b;
  }
`;

export const PurpleButton = styled(Button)`
  text-transform: none !important;
  background: #572e72 !important;
  font-weight: 600 !important;
  color: white !important;
  font-size: 12px !important;
  &:hover {
    background: #2f193b !important;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 50px !important;
    font-size: 10px !important;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CustomTableContainer = styled(TableContainer)`
  max-width: 1200px !important;

  border: none;
  border: 0px !important;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1200px) {
    max-width: 1080px !important;
  }
`;

export const CustomTableCell = styled(TableCell)``;

export const BadgeFontSize = styled.p`
  font-weight: 800;
  color: white;
  font-size: 11px;
  @media only screen and (max-width: 1450px) {
    font-size: 10px;
  }
`;

export const CustomTableRow = styled(TableRow)`
  max-height: 50px !important;
  &:hover {
    background: whitesmoke;
    cursor: pointer;
  }

  &::active {
    background: whitesmoke;
    cursor: pointer;
  }
`;

export const BadgeCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 25px;
  width: 25px;
  cursor: default;

  border-radius: 100px;
  background: #572e72;
`;
