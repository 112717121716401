import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { timeOnly } from "@onwardcare/core/utils/date-format";

const BidsTable = ({ bestTimeBids }) => {
  const columns = [
    {
      id: "bid_start_time",
      label: "Bid Time",
      minWidth: 50,
      format: (value) => timeOnly(value),
    },
    { id: "driver_name", label: "Driver", minWidth: 100 },
    { id: "transport_partner", label: "Transport Partner", minWidth: 100 },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "visible" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bestTimeBids.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BidsTable;
