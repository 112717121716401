import React from "react";
import { FontWeight } from "../../globalStyles/emotion";

const RiderHeightWeight = ({
  riderHeightDisplay,
  riderWeightDisplay,
  riderWillUseProvidersWheelchair,
}) => {
  return (
    <div style={{}}>
      {riderHeightDisplay && (
        <div style={{ display: "flex", gap: "5px" }}>
          <p style={{ width: "62px" }}>Height: </p>
          <FontWeight>{riderHeightDisplay}</FontWeight>
        </div>
      )}
      {riderWeightDisplay && (
        <div style={{ display: "flex", gap: "5px" }}>
          <p style={{ width: "62px" }}>Weight: </p>
          <FontWeight>{riderWeightDisplay}</FontWeight>
        </div>
      )}
      {/* {riderWillUseProvidersWheelchair && <div style={{ display: "flex", gap: "5px" }}><p>Rider Will Provide Wheelchair</p> <FontWeight>{riderWillUseProvidersWheelchair}</FontWeight></div>} */}
    </div>
  );
};

export default RiderHeightWeight;
