import axios from "axios";
import { apiUrl} from "./headerConfigs";
const fetchTransportPartner = async () => {
  return axios
    .get(
      `${apiUrl}/TransportPartner`
    )
    .catch((error) => {
      console.log(error);
    });
}

export { fetchTransportPartner };