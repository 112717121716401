import ENV from "../constants/Environment";
const { apiUrl } = ENV;

const baseHeaders = {
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
};

const config = {
  headers: baseHeaders,
};

const configWithUserId = {
  headers: {
    ...baseHeaders,
    "User-Id": localStorage.getItem("userid"),
  },
};

export { config, configWithUserId, apiUrl };