import { ConfirmationDialog } from "../../components/dialogs";
import { Alert } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import {
  FontWeight,
  SecondaryButton,
  PurpleButton,
} from "../../globalStyles/emotion";
import ENV from "../../constants/Environment";
import handleLogout from "../auth/Logout";

const { apiUrl } = ENV;

const DialogMessage = ({
  setOpen,
  setPartnerId,
  savedPartnerId,
  id,
  driverFirstName,
  driverLastName,
  driver,
  driverNotes,
  setClaimRideMessage,
  setAlertColor,
  setToggleClaimId,
  setReloadData,
  setDriverFirstName,
  setDriverLastName,
  setDisable,
  open,
}) => {
  const [error, setError] = useState(null);
  const [isValidationError, setIsValidationError] = useState(false);

  const handleButtonClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      setPartnerId(savedPartnerId);
    }
  };

  const handleClaimRide = (id) => {
    setError(null);
    setIsValidationError(false);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "User-Id": localStorage.getItem("userid"),
      },
    };
    axios
      .post(
        `${apiUrl}/Rides/ClaimRide?driverid=${driver}&rideid=${id}&notes=${driverNotes}`,
        null,
        config
      )
      .then((response) => {
        setClaimRideMessage("Ride has been claimed");

        setToggleClaimId(null);
        setOpen(false);
        setPartnerId(savedPartnerId);
        setReloadData(true);
        setDriverFirstName("");
        setDriverLastName("");
        setDisable(true);
      })
      .catch((error) => {
        // TODO: Encapsulate this logic into common component
        // Taken from https://axios-http.com/docs/handling_errors
        if (error.response.status === 401) {
          handleLogout();
        } else if (error.response.status === 422) {
          setIsValidationError(true);
          setError(error.response.data.detail);
        } else if (error.response.data) {
          setError(
            `${error.response.data.title}\n${error.response.data.detail}`
          );
        } else {
          setError("Something went wrong trying to claim the ride.");
        }

        console.log(error);
      })
      .then(() => {
        setInterval(() => {
          setReloadData(false);
        }, 2000);
      });
  };

  return (
    <ConfirmationDialog open={open} onClose={handleButtonClose}>
      <ConfirmationDialog.Title>Claim Confirmation</ConfirmationDialog.Title>

      <ConfirmationDialog.Body>
        {error ? (
          <Alert style={{ marginBottom: "10px" }} severity="error">
            {error}
          </Alert>
        ) : null}

        {isValidationError === false && (
          <p>
            Are you sure you want to claim a trip for driver{" "}
            <FontWeight>
              {driverFirstName} {driverLastName}?
            </FontWeight>
          </p>
        )}
      </ConfirmationDialog.Body>

      <ConfirmationDialog.Footer>
        <SecondaryButton variant="outlined" onClick={handleButtonClose}>
          {isValidationError ? "Close" : "No"}
        </SecondaryButton>

        {isValidationError === false && (
          <PurpleButton variant="contained" onClick={() => handleClaimRide(id)}>
            Yes
          </PurpleButton>
        )}
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};

export default DialogMessage;
