import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const DriverSection = ({
  driver,
  setDriver,
  partnerId,
  setDriverFirstName,
  setDriverLastName,
  driverData,
}) => {
  const renderMenuItems = (driverData) => {
    const filteredDriverData = driverData.filter( ({ transportPartnerId }) => transportPartnerId === partnerId);
    const driverList = partnerId === -1 ? driverData : filteredDriverData;

    return (
      driverList.map(({ firstName, lastName, id }) => (
        <MenuItem key={id} value={id}>
          {firstName} {lastName}
        </MenuItem>
      ))
    );
  }

  const handleSelectDriver = (event, firstName) => {
    setDriver(event.target.value);
    setDriverFirstName(firstName.props.children[0]);
    setDriverLastName(firstName.props.children[2]);
  }

  return (
    <>
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel id="demo-simple-select-label">Driver</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Driver"
          value={driver}
          onChange={(event, firstName) => handleSelectDriver(event, firstName)}
        >
          {renderMenuItems(driverData)}
        </Select>
      </FormControl>
    </>
  )
};

export default DriverSection;