import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tabs from "../tabs/Tabs";
import "./DataTable.css";
import Alert from "@mui/material/Alert";
import {Container, Snackbar, List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,} from "@mui/material";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import Help from '@material-ui/icons/Help';
import CloseIcon from "@mui/icons-material/Close";
import PendingView from "../../Views/pending/Pages/PendingView";
import HistoryView from "../../Views/history/Pages/HistoryView";
import ConfirmedView from "../../Views/confirmed/Pages/ConfirmedView";
import Search from "../search/Search";
import { TransportTypeFilter } from "./TransportTypeFilter";
import axios from "axios";
import ENV from "../../constants/Environment";
import actionCableService from "../../services/ActionCableService";
import { NotificationService } from "../../services/NotificationService";
import handleLogout from "../auth/Logout";
import { RideAlerts } from "./RideAlerts";
import { makeStyles } from '@material-ui/core/styles';

const { onwardApiUrl, apiUrl } = ENV;
const channelName = "RidesChannel";


const contactInfo = {
  email: 'support@onwardrides.com',
  telephone: {
    display: '1 (800) 700-4797',
    value: '+18007004797',
  },
};

const useStyles = makeStyles(theme => ({
  helpIcon: {
    minWidth: '40px',
  },
  needHelp: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  coverContainer: {
    objectFit: 'cover',
    backgroundImage: `radial-gradient(
      72.78% 143.92% at 9.17% 16.99%,
      rgba(87, 46, 114, 0.4) 0%,
      rgba(130, 96, 162, 0.4) 49.89%,
      rgba(147, 200, 214, 0.4) 100%
    )`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
  }
}));

const getSubscriberName = () => {
  const admin = localStorage.getItem("admin") === "true";
  const partnerId = localStorage.getItem("partnerId");
  return admin ? "rides/admin" : `rides/transport_partners/${partnerId}`;
};

export default function Concierge() {
  const [toggleTab, setToggleTab] = useState(1);
  const [openSelect, setOpenSelect] = useState(false);
  const [claimRideMessage, setClaimRideMessage] = useState(null);
  const [submitBidMessage, setSubmitBidMessage] = useState(null);
  const [notificationService] = useState(new NotificationService());
  const [showNotificationAlert, setShowNotificationAlert] = useState(false);
  const [transportTypes, setTransportTypes] = useState([]);
  const [transportTypeNumbers, setTransportTypeNumber] = useState([]);
  const [query, setQuery] = useState("");
  const [unseenRides, setUnseenRides] = useState([]);
  const [rideAlerts, setRideAlerts] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    axios
      .get(`${onwardApiUrl}/api/transport_types`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        setTransportTypes(response.data.message);
      });
  }, []);

  const addRideFilter = ({ id, status }) => {
    let tab = 1;

    if (status === "Ongoing") {
      tab = 2;
    } else if (status === "Completed") {
      tab = 3;
    }

    setToggleTab(tab);
    setQuery(id);
    setSelectedRowId(id);
  };

  const handleFilterTransportType = (event) => {
    if (event.target.value.includes(0)) {
      if (transportTypeNumbers.length !== 0) {
        resetFilter();
      }
      return;
    }
    localStorage.setItem(
      "transportTypeNumbers",
      JSON.stringify(event.target.value)
    );
    setTransportTypeNumber(event.target.value);
  };

  const resetFilter = () => {
    setTransportTypeNumber([]);
    localStorage.setItem("transportTypeNumbers", JSON.stringify([]));
  };

  useEffect(() => {
    const transportTypeNumbersJSON = localStorage.getItem(
      "transportTypeNumbers"
    );
    if (!!transportTypeNumbersJSON) {
      setTransportTypeNumber(JSON.parse(transportTypeNumbersJSON));
    }
  }, []);

  useEffect(() => {
    setShowNotificationAlert(notificationService.permissionUnspecified);
  }, [notificationService.permissionUnspecified]);

  useEffect(() => {
    const subscription = actionCableService.subscribeToChannel(
      channelName,
      getSubscriberName(),
      (data) => {
        if (data.ride_id === undefined || data.event_name === undefined) {
          return;
        }

        if (data.event_name === "created") {
          setUnseenRides((prevUnseenRides) => [
            ...prevUnseenRides,
            data.ride_id,
          ]);
          notificationService.notify(
            "New Pending Ride",
            "A new ride is available to be claimed."
          );
        }

        if (data.event_name === "not_started") {
          axios
            .get(`${apiUrl}/Rides/${data.ride_id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            })
            .then((response) => {
              setRideAlerts((prevRideAlerts) => [
                ...prevRideAlerts,
                {
                  id: response.data.id,
                  status: response.data.rideCompletionStatusRollup,
                },
              ]);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                handleLogout();
              }
            });
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [notificationService]);

  return (
    <>
      {claimRideMessage !== null ? (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setClaimRideMessage(null);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          variant="outlined"
          severity="success"
          style={{
            right: "50px",
            zIndex: 999,
            width: "300px",
            position: "absolute",
            background: "white",
            top: "100px",
            borderTop: "grey",
            borderRight: "grey",
            borderBottom: "grey",
            boxShadow: "3px 3px 3px  grey",
          }}
        >
          {claimRideMessage}
        </Alert>
      ) : null}

      {showNotificationAlert && (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowNotificationAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          variant="outlined"
          severity="warning"
          style={{
            right: "50px",
            zIndex: 999,
            width: "300px",
            position: "absolute",
            background: "white",
            top: "100px",
            borderTop: "grey",
            borderRight: "grey",
            borderBottom: "grey",
            boxShadow: "3px 3px 3px  grey",
          }}
        >
          To enhance your experience and receive real-time updates, please&nbsp;
          <a
            href="https://onwardrides.zendesk.com/hc/en-us"
            rel="noopener noreferrer"
            target="_blank"
          >
            enable browser notifications.
          </a>
        </Alert>
      )}

      {submitBidMessage !== null ? (
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitBidMessage(null);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          variant="outlined"
          severity="success"
          style={{
            right: "50px",
            zIndex: 999,
            width: "300px",
            position: "absolute",
            background: "white",
            top: "100px",
            borderTop: "grey",
            borderRight: "grey",
            borderBottom: "grey",
            boxShadow: "3px 3px 3px  grey",
          }}
        >
          {submitBidMessage}
        </Alert>
      ) : null}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="info"
          sx={{
            background: "white",
            borderTop: "grey",
            borderRight: "grey",
            borderBottom: "grey",
            boxShadow: "3px 3px 3px  grey",
          }}
        >
          Some rides are no longer available to claim.
        </Alert>
      </Snackbar>

      <RideAlerts
        rides={rideAlerts}
        onClick={(ride) => addRideFilter(ride)}
        onClose={(ride) => {
          const rides = rideAlerts.filter((r) => r.id !== ride.id);
          setRideAlerts(rides);
        }}
      />


      <div style={{
        minHeight: '100%',
        height: 'max-content',
      }}
        className={classes.coverContainer}
      >
      <Container
        maxWidth="xl"
        style={{
          minHeight: '100vh',
          display: "flex",
          flexDirection: "column",
          paddingTop: 80,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ width: "600px", paddingLeft: "20px", paddingTop: "10px" }}
          >
            <Tabs
              toggleTab={toggleTab}
              setToggleTab={setToggleTab}
              unseenRides={unseenRides}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <TransportTypeFilter
                openSelect={openSelect}
                transportTypeNumbers={transportTypeNumbers}
                transportTypes={transportTypes}
                setOpenSelect={setOpenSelect}
                handleFilterTransportType={handleFilterTransportType}
              />
            </div>

            <div style={{ marginLeft: "10px" }}>
              <Search query={query} setQuery={setQuery} />
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            overflowY: 'scroll'
          }}
        >
        {toggleTab === 1 && (
          <PendingView
            transportTypeNumbers={transportTypeNumbers}
            setClaimRideMessage={setClaimRideMessage}
            setSubmitBidMessage={setSubmitBidMessage}
            query={query}
            unseenRides={unseenRides}
            setUnseenRides={setUnseenRides}
            setOpenSnackbar={setOpenSnackbar}
            selectedRowId={selectedRowId}
          />
        )}

        {toggleTab === 2 && (
          <ConfirmedView
            transportTypeNumbers={transportTypeNumbers}
            setClaimRideMessage={setClaimRideMessage}
            claimRideMessage={claimRideMessage}
            query={query}
            selectedRowId={selectedRowId}
          />
        )}

        {toggleTab === 3 && (
          <HistoryView
            transportTypeNumbers={transportTypeNumbers}
            query={query}
            selectedRowId={selectedRowId}
          />
        )}
        </div>
        <footer
          style={{
            paddingTop:20,
            minHeight: 100,
          }}
        >
        <div style={{display: 'flex', justifyContent: 'flex-start'}} >
          <Typography className={classes.needHelp} variant="subtitle2">
            Need help?
          </Typography>
          <div>
            <ListItem
              key="call"
              component="a"
              href={`tel://${contactInfo.telephone.value}`}
            >
              <ListItemIcon className={classes.helpIcon}>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText secondary={contactInfo.telephone.display} />
            </ListItem>
          </div>
          <div>
            <ListItem
              key="email"
              component="a"
              href={`mailto:${contactInfo.email}`}
              target="_blank"
            >
              <ListItemIcon className={classes.helpIcon}>
                <MailIcon />
              </ListItemIcon>
              <ListItemText secondary={contactInfo.email} />
            </ListItem>
          </div>

          <div>

            <ListItem
              button
              key="help"
              component="a"
              href="https://onwardrides.zendesk.com/hc/en-us/categories/8697564898583-NEMT-Partners"
              target="_blank"
            >
              <ListItemIcon className={classes.helpIcon}>
                <Help />
              </ListItemIcon>
              <ListItemText secondary="Help Library" />
            </ListItem>
          </div>

        </div>

      </footer>
      </Container>
      </div>

    </>
  );
}
