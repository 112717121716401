import "./App.css";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Dashboard from "./components/dashboard/Dashboard";
import Navbar from "./components/navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useContext } from "react";
import { AdminContext } from "./components/context/Context";
import DownloadCsv from "./components/downloadCsv/DownloadCsv";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const isAdmin = localStorage.getItem("admin");
  const partnerId = localStorage.getItem("partnerId");
  const canAccessRoute = isAdmin === "true" || partnerId !== "";
  const [value] = useContext(AdminContext);
  const client = new QueryClient();

  if (!value) {
    zE("messenger:set", "cookies", false);
  }

  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <Router>
          <Routes>
            <Route path="/Login" exact={true} element={<Login />} />
          </Routes>
          {value !== null ? <Navbar /> : null}
          <Routes>
            <Route path="/" element={<Dashboard />} />
          </Routes>
          <Routes>
            <Route path="/register" exact={true} element={<Register />} />
          </Routes>
          { canAccessRoute ?
            (<Routes>
              <Route
                path="/downloadHistoricalRides"
                exact={true}
                element={<DownloadCsv exportData="historical" />}
              />
              <Route
                path="/downloadUpcomingRides"
                exact={true}
                element={<DownloadCsv exportData="upcoming" />}
              />
            </Routes>) : null }
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
