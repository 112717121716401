import { useContext } from "react";
import "./dashboard.css";
import { useLocation, Navigate } from "react-router-dom";
import Concierge from "../dataTable/Concierge";
import { AdminContext } from "../context/Context";
import { useZendesk } from "../zendesk/Zendesk";

export default function CustomizedTables() {
  const [value] = useContext(AdminContext);
  const userId = localStorage.getItem("userid");
  let location = useLocation();

  useZendesk(userId);

  return (
    <>
      {value === null && (
        <Navigate to="/Login" state={{ from: location }} replace />
      )}

      {value !== null && value !== "" && <Concierge />}
    </>
  );
}
