import React from "react";
import { FontWeight } from "../../globalStyles/emotion";
import {
  dateOnly,
  timeOnly,
  timeRange,
} from "@onwardcare/core/utils/date-format";

const DashTimeFormat = ({ requestedStartTime, requestedEndTime }) => {
  return (
    <FontWeight>
      {dateOnly(requestedStartTime)}{" "}
      {requestedEndTime
        ? timeRange(requestedStartTime, requestedEndTime)
        : timeOnly(requestedStartTime)}
    </FontWeight>
  );
};

export default DashTimeFormat;
