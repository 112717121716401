import { Alert } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import ENV from "../../constants/Environment";
import { DisabledButton, SecondaryButton } from "../../globalStyles/emotion";
import { ConfirmationDialog } from "../dialogs";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import handleLogout from "../auth/Logout";

const { apiUrl } = ENV;

export default function NoShowRideCard({
  rideId,
  onClose,
  setClaimRideMessage,
  setReloadData,
}) {
  const [noShowNotes, setNoShowNotes] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);

  const notesUpdated = (notes) => {
    setNoShowNotes(notes);
    handleNotesValidation(notes);
  };

  const handleNotesValidation = (notes) => {
    if (notes.trim() === "") {
      setValidationError(true);
      setDisabled(true);
      return false;
    } else {
      setValidationError(false);
      setDisabled(false);
      return true;
    }
  };

  const handleNoShowRide = (rideId) => {
    setError(null);

    if (handleNotesValidation(noShowNotes) === false) {
      return;
    }

    setDisabled(true);

    axios
      .post(
        `${apiUrl}/Rides/NoShow`,
        {
          ride_id: rideId,
          notes: noShowNotes.trim(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "User-Id": localStorage.getItem("userid"),
          },
        }
      )
      .then(() => {
        setError(null);
        onClose();
        setReloadData(true);
        setClaimRideMessage("Ride has been marked as no show");
      })
      .then(() => {
        setInterval(() => {
          setReloadData(false);
        }, 2000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handleLogout();
        } else if (error.response.data.detail) {
          setError(error.response.data.detail);
        } else {
          setError("Something went wrong trying to mark the ride as No Show.");
        }

        setDisabled(false);
      })
      .then(() => {
        if (error) {
          // Don't do anything if there is an error rendered.
          return;
        }

        setInterval(() => {
          setDisabled(false);
        }, 2000);
      });
  };

  return (
    <ConfirmationDialog open={rideId}>
      <ConfirmationDialog.Body>
        {error ? (
          <Alert style={{ marginBottom: "10px" }} severity="error">
            {error}
          </Alert>
        ) : null}

        <p>Please add some detail on what happened with this ride:</p>

        <div style={{ marginTop: "20px" }}>
          <InputLabel for="no-show-notes" error={validationError}>
            Notes
          </InputLabel>
          <TextField
            id="no-show-notes"
            variant="outlined"
            // This is needed to force the text field to expand the width of the form
            style={{ width: "100%" }}
            onChange={(event) => {
              notesUpdated(event.target.value);
            }}
            error={validationError}
            helperText={validationError ? "Notes is required." : null}
          />
        </div>

        <p style={{ marginTop: "20px" }}>
          By clicking "Mark No Show", you are confirming that you have:
        </p>

        <ul style={{ marginTop: "5px", listStyle: "disc" }}>
          <li style={{ marginLeft: "25px", marginTop: "5px" }}>
            Called the rider and/or the coordinator
          </li>
          <li style={{ marginLeft: "25px", marginTop: "5px" }}>
            Checked in with the staff or knocked on the door
          </li>
        </ul>
      </ConfirmationDialog.Body>

      <ConfirmationDialog.Footer>
        <SecondaryButton variant="outlined" onClick={onClose}>
          Cancel
        </SecondaryButton>

        <DisabledButton
          variant="contained"
          disabled={disabled}
          onClick={() => handleNoShowRide(rideId)}
        >
          Mark No Show
        </DisabledButton>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
}
