import { Dialog } from "@mui/material";

export function ConfirmationDialog({maxWidth, fullWidth, children, open, onClose }) {
  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
    >
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {children}
      </div>
    </Dialog>
  );
}

function Title({ children }) {
  return <h1>{children}</h1>;
}

ConfirmationDialog.Title = Title;

function Body({ children }) {
  return <div>{children}</div>;
}

ConfirmationDialog.Body = Body;

function Footer({ children }) {
  return (
    <div
      style={{
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {children}
    </div>
  );
}

ConfirmationDialog.Footer = Footer;
