import axios from "axios";
import { config, configWithUserId, apiUrl } from "./headerConfigs";

const getDriversDetails = ({
  transportTypeId,
  partnerId,
}) => {
  let usePartner = partnerId !== -1 ? `&partnerId=${partnerId}` : "";

  return axios
    .get(
      `${apiUrl}/Driver/GetDrivers?transportTypeId=${transportTypeId}${usePartner}`,
      {}
    )
}

const getBestTimeOptions = (rideId) => {
  return axios
    .get(
      `${apiUrl}/Rides/GetBestTimeOptions?rideId=${rideId}`,
      config
    ).catch((error) => {
      console.log(error);
      return {data: { bestTimeOptions: [] }};
    })
}

const getBestTimeBids = (rideId) => {
  return axios
    .get(
      `${apiUrl}/Rides/GetBestTimeBids?rideId=${rideId}`,
      configWithUserId
    ).catch((error) => {
      console.log(error);
    })
}

export { getDriversDetails, getBestTimeOptions, getBestTimeBids };