import { memo } from "react";

const TabBadge = function ({ unseenRides }) {
  return (
    <div
      style={{
        marginLeft: "8px",
        padding: "1px 8px",
        fontSize: "14px",
        fontWeight: "600",
        background: "#d32f2f",
        borderRadius: "25px",
        color: "white",
      }}
    >
      {unseenRides.length}
    </div>
  );
};

const Tabs = memo(({ toggleTab, setToggleTab, unseenRides = [] }) => {
  const handleTab = (index) => {
    setToggleTab(index);
  };

  return (
    <div style={{}}>
      <div className="tabs is-left is-boxed">
        <ul>
          <li
            className={toggleTab === 1 ? "is-active" : ""}
            onClick={() => handleTab(1)}
          >
            <a>
              Pending
              {unseenRides.length > 0 && (
                <TabBadge unseenRides={unseenRides} />
              )}
            </a>
          </li>
          <li
            className={toggleTab === 2 ? "is-active" : ""}
            onClick={() => handleTab(2)}
          >
            <a>Confirmed</a>
          </li>

          <li
            className={toggleTab === 3 ? "is-active" : ""}
            onClick={() => handleTab(3)}
          >
            <a>History</a>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default Tabs;
