import { Dialog } from "@mui/material";
import React from "react";
import { PurpleButton } from "../../globalStyles/emotion";
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  DialogContent,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import "./MncsDialog.css";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const SplitNotes = ({ notes }) => {
  if (!notes) {
    return null;
  }

  return (
    <span
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "0.5rem",
        paddingTop: "0.5rem",
      }}
    >
      {notes.split("\n").map((n) => (
        <span style={{ margin: 0 }} key={n}>
          {n}
        </span>
      ))}
    </span>
  );
};

const MncsDialog = ({ mncsAnswers, open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const medicalNecessities = React.useMemo(() => {
    if (mncsAnswers) {
      return mncsAnswers?.reduce((acc, prompt) => {
        if (prompt.group1) {
          const element = acc.find((el) => el.group === prompt.group1);
          if (element) {
            const subElement = element.subGroup.find(
              (el) => el.name === prompt.group2
            );

            if (subElement) {
              subElement.promts.push({
                name: prompt.name,
                value: prompt.value,
                notes: prompt.notes,
                type: prompt.fieldType,
              });
            } else {
              const subElement = {
                name:
                  prompt.fieldType === "date"
                    ? prompt.name || prompt.group2
                    : prompt.group2,
                promts: [
                  {
                    name: prompt.name,
                    value: prompt.value,
                    notes: prompt.notes,
                    type: prompt.fieldType,
                  },
                ],
              };
              element.subGroup.push(subElement);
            }
          } else {
            const element = {
              group: prompt.group1,
              subGroup: [
                {
                  name: prompt.group2 || prompt.name || "",
                  promts: [
                    {
                      name: prompt.name,
                      value: prompt.value,
                      notes: prompt.notes,
                      type: prompt.fieldType,
                    },
                  ],
                },
              ],
            };
            acc.push(element);
          }
        } else {
          let subElement;
          let subElementIndex = -1;

          acc.forEach((el) => {
            subElementIndex = el.subGroup.findIndex(
              (subEl) => subEl.name === prompt.group2
            );
            if (subElementIndex !== -1) {
              subElement = el.subGroup[subElementIndex];
            }
          });

          if (subElement && subElementIndex !== -1) {
            subElement.promts.push({
              name: prompt.name,
              value: prompt.value,
              notes: prompt.notes,
              type: prompt.fieldType,
            });
          } else {
            subElement = {
              name:
                prompt.fieldType === "DATE"
                  ? prompt.name || prompt.group2
                  : prompt.group2,
              promts: [
                {
                  name: prompt.name,
                  value: prompt.value,
                  notes: prompt.notes,
                  type: prompt.fieldType,
                },
              ],
            };
            acc.push({
              subGroup: [subElement],
            });
          }
        }
        return acc;
      }, []);
    }
  }, [mncsAnswers]);

  return (
    <Dialog
      maxWidth="lg"
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent>
        <Typography style={{ textAlign: "center" }} component="h1" variant="h4">
          Medical Necessity Certification Statement
        </Typography>

        <Grid container lg={12} md={12} xs={12}>
          {medicalNecessities &&
            medicalNecessities.map((element) => {
              return (
                <>
                  {element.group ? (
                    <Typography
                      style={{ marginTop: "2rem" }}
                      component="h4"
                      variant="h5"
                    >
                      <SplitNotes notes={element.group} />
                    </Typography>
                  ) : null}
                  <Grid
                    className={!!element.group ? "mncs-dialog-group" : ""}
                    item
                    lg={!!element.group ? 12 : 6}
                    md={!!element.group ? 12 : 6}
                    xs={12}
                  >
                    {element.subGroup.map((subElement, ind) => (
                      <Grid
                        style={{
                          paddingTop: "1rem",
                        }}
                        item
                        xs={12}
                      >
                        <Typography component="h4" variant="h6">
                          {capitalizeFirstLetter(subElement.name)
                            .split("_")
                            .join(" ")}
                        </Typography>
                        {subElement.promts.map((p) => {
                          if (p.type === "boolean") {
                            return (
                              <div style={{ display: "flex" }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled
                                      id={`mncsOptions.${p.value}`}
                                      checked={p.value === "true"}
                                    />
                                  }
                                  label={
                                    p.name || <SplitNotes p={p.notes} /> || null
                                  }
                                />
                              </div>
                            );
                          } else if (p.type === "string") {
                            return (
                              <>
                                <Typography variant="body2">
                                  {p.value}
                                </Typography>
                              </>
                            );
                          } else if (p.type === "date") {
                            return (
                              <DatePicker
                                id={`mncsOptions.${p.value}`}
                                autoOk
                                disableFuture
                                format="l"
                                label={p.name}
                                value={
                                  mncsAnswers.find(
                                    (answer) => answer.slug === p.value
                                  )?.value
                                }
                                onChange={() => {}}
                              />
                            );
                          }
                        })}
                      </Grid>
                    ))}
                  </Grid>
                </>
              );
            })}
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "50px",
          }}
        >
          <PurpleButton variant="contained" onClick={handleClose}>
            Close
          </PurpleButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MncsDialog;
