import jwtDecode from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";
export const AdminContext = createContext();
export const AdminProvider = (props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(localStorage.getItem("firstName"));
  }, [value]);

  return (
    <AdminContext.Provider value={[value, setValue]}>
      {props.children}
    </AdminContext.Provider>
  );
};
