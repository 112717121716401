import { Tooltip } from "@mui/material";
import React from "react";
import { FontWeight } from "../../globalStyles/emotion";
import { PurpleButton } from "../../globalStyles/emotion";
import MncsDialog from "../mncsDialog/MncsDialog";
import { LocationPhoto } from "./LocationPhoto";

const Location = ({
  locationDirection,
  name,
  address,
  notes,
  roomNumber,
  photo,
}) => {
  return (
    <div>
      <div>
        <p
          style={{
            fontSize: "1rem",
            textTransform: "uppercase",
          }}
        >
          {locationDirection} Location
        </p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <>{name === address ? null : <FontWeight>{name}</FontWeight>}</>
        <FontWeight>{address}</FontWeight>
      </div>

      {notes === null ? null : (
        <>
          <p style={{}}>{locationDirection} Location Notes:</p>{" "}
          {notes.length > 50 ? (
            <Tooltip enterDelay={500} title={notes} placement="top">
              <FontWeight>{notes.slice(0, 50)}...</FontWeight>
            </Tooltip>
          ) : (
            <FontWeight> {notes}</FontWeight>
          )}
        </>
      )}

      <div style={{ display: "flex", gap: "5px" }}>
        <p>{locationDirection} Room Number: </p>{" "}
        {roomNumber === null ? (
          <FontWeight> N/A</FontWeight>
        ) : (
          <FontWeight> {roomNumber}</FontWeight>
        )}
      </div>

      {photo && (
        <div>
          <LocationPhoto locationPhoto={photo} />
        </div>
      )}
    </div>
  );
};

const LocationInformation = ({
  startLocationName,
  startLocationAddress,
  startLocationNotes,
  startLocationPhoto,
  endLocationName,
  endLocationNotes,
  endLocationAddress,
  endLocationPhoto,
  startLocationRoomNumber,
  endLocationRoomNumber,
  rideNotes,
  mncsAnswers,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
      <Location
        locationDirection="Pickup"
        name={startLocationName}
        address={startLocationAddress}
        notes={startLocationNotes}
        roomNumber={startLocationRoomNumber}
        photo={startLocationPhoto}
      />

      <Location
        locationDirection="Drop-off"
        name={endLocationName}
        address={endLocationAddress}
        notes={endLocationNotes}
        roomNumber={endLocationRoomNumber}
        photo={endLocationPhoto}
      />

      <div>
        {rideNotes === null || rideNotes === "" ? null : (
          <>
            <p>Ride Notes:</p>{" "}
            {rideNotes.length > 50 ? (
              <Tooltip enterDelay={500} title={rideNotes} placement="top">
                <FontWeight>{rideNotes.slice(0, 50)}...</FontWeight>
              </Tooltip>
            ) : (
              <FontWeight> {rideNotes}</FontWeight>
            )}
          </>
        )}
        {mncsAnswers.length === 0 ? (
          ""
        ) : (
          <>
            <div className="display-button-flex">
              <PurpleButton onClick={handleOpen}>
                Medical Necessity Certification Statement
              </PurpleButton>
            </div>
            <MncsDialog
              mncsAnswers={mncsAnswers}
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LocationInformation;
