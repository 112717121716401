import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";
import { SecondaryButton } from "../../../globalStyles/emotion";
import { useEffect, useState } from "react";
import PendingViewSidePanel from "../components/PendingViewSidePanel";
import TableHeader from "../../../components/tableHeader/TableHeader";
import TableRowData from "../../../components/tableRowData/TableRowData";
import axios from "axios";
import ENV from "../../../constants/Environment";
import handleLogout from "../../../components/auth/Logout";
import { Refresh } from "@mui/icons-material";

const { apiUrl } = ENV;

const PendingView = ({
  transportTypeNumbers,
  setClaimRideMessage,
  setSubmitBidMessage,
  unseenRides,
  setUnseenRides,
  setOpenSnackbar,
  query,
  selectedRowId,
}) => {
  const userid = localStorage.getItem("userid");
  const [loading, setLoading] = useState(true);
  const [transportPartnerData, setTransportPartnerData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [rides, setRides] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortHeader, setSortHeader] = useState("availableAt");
  const [sortDirection, setSortDirection] = useState(-1);
  const [previousFilters, setPreviousFilters] = useState({
    query,
    transportTypeIds: transportTypeNumbers.join(","),
  });
  const [metadata, setMetadata] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 1,
  });

  useEffect(
    () => {
      const pendingRowId = window.sessionStorage.getItem("pendingRowId");

      if (pendingRowId !== null && pendingRowId !== "") {
        setRowId(+pendingRowId);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [
      // We only want this to run once!
    ]
  );

  useEffect(() => {
    setRowId(selectedRowId);
  }, [selectedRowId]);

  useEffect(() => {
    if (rowId !== null) {
      window.sessionStorage.setItem("pendingRowId", rowId);
    }
  }, [rowId]);

  useEffect(() => {
    // Do we really need to reload this data any time someone claims a ride?
    axios.get(`${apiUrl}/TransportPartner/Grouped`).then((response) => {
      setTransportPartnerData(response.data);
    });
  }, [reloadData]);

  useEffect(() => {
    setLoading(true);

    const transportTypeIds = transportTypeNumbers.join(",");

    if (
      page !== 1 &&
      (query !== previousFilters.query ||
        transportTypeIds !== previousFilters.transportTypeIds)
    ) {
      setPage(1);
      setPreviousFilters({ query, transportTypeIds });
      return;
    }

    setPreviousFilters({ query, transportTypeIds });

    const params = {
      userId: userid,
      pageNumber: page,
      pageSize: rowsPerPage,
      sort: `${sortDirection === 1 ? "" : "-"}${sortHeader}`,
    };

    if (query !== "") {
      params.query = query;
    }

    if (transportTypeNumbers.length > 0) {
      params.transportTypeIds = transportTypeIds;
    }

    const searchParams = new URLSearchParams(params);

    axios
      .get(`${apiUrl}/Rides/PendingRides?${searchParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setMetadata(response.data.metadata);
        setRides(response.data.data);
        setUnseenRides((prevUnseenRides) => {
          // Show the toast if some of the rides were claimed or cancelled
          // before the user clicked the refresh button.
          if (prevUnseenRides.length > 0) {
            const rideIds = response.data.data.map((ride) => ride.id);
            const expectedMatchCount = Math.min(
              prevUnseenRides.length,
              response.data.metadata.pageSize
            );
            const actualMatchCount = rideIds.filter((rid) =>
              prevUnseenRides.includes(rid)
            ).length;

            setOpenSnackbar(actualMatchCount < expectedMatchCount);
          }
          return [];
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handleLogout();
        }
      });
  }, [
    // We purposely don't want this to re-run when the previousFilters change.
    page,
    query,
    reloadData,
    rowsPerPage,
    sortHeader,
    sortDirection,
    transportTypeNumbers,
    userid,
  ]);

  useEffect(() => {
    // Set the selected ride to the first ride in the list if the user doesn't have one selected.
    if (rides.length > 0 && rowId === null) {
      setRowId(rides[0].id);
    }
  }, [rides, rowId, setRowId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleRefreshRidesClick = () => {
    setSortHeader("availableAt");
    setSortDirection(-1);
    setPage(1);
    setReloadData((prevReloadData) => !prevReloadData);
  };

  return (
    <>
      <TableContainer
        sx={{
          position: "relative",
          flex: 1,
          borderTopLeftRadius: "25px",
          background: "white",
        }}
      >
        {loading && (
          <CircularProgress
            style={{
              position: "absolute",
              top: "15px",
              left: "20px",
              zIndex: "50",
            }}
            color="secondary"
          />
        )}

        {!loading && unseenRides.length > 0 && (
          <SecondaryButton
            variant="outlined"
            onClick={handleRefreshRidesClick}
            style={{
              position: "absolute",
              top: "15px",
              left: "20px",
              zIndex: "50",
            }}
          >
            <Refresh style={{ fontSize: "16px" }} />
            <span style={{ marginLeft: "2px" }}>Show Latest Rides</span>
          </SecondaryButton>
        )}

        <Table aria-label="simple table" style={{ position: "relative" }}>
          <TableHead style={{ position: "sticky", top: "0", zIndex: "1" }}>
            <TableHeader
              setSortHeader={setSortHeader}
              sortHeader={sortHeader}
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              showCreatedTime={true}
            />
          </TableHead>
          <TableBody>
            <TableRowData
              data={rides}
              rowId={rowId}
              setRowId={setRowId}
              showCreatedTime={true}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        style={{
          flex: 1,
          background: "white",
          borderTopRightRadius: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <PendingViewSidePanel
          rowId={rowId}
          pendingData={rides}
          transportPartnerData={transportPartnerData}
          setClaimRideMessage={setClaimRideMessage}
          setReloadData={setReloadData}
          setSubmitBidMessage={setSubmitBidMessage}
        />
        <TablePagination
          style={{}}
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          rowsPerPage={metadata.pageSize}
          page={metadata.currentPage - 1} // Pagination is index based
          count={metadata.totalCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default PendingView;
