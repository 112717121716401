import { FontWeight } from "../../globalStyles/emotion";
import { IconButton } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { Box } from "@mui/system";
import personStyles from "../personDetails/styles.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import Popper from "@mui/material/Popper";
import { ContactsPopper } from "../personDetails/contactsPopper";
import React from "react";

const BriefDetail = ({ name, title, number, email }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const id = open ? "simple-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      {name === undefined ? null : (
        <div
          style={{ display: "flex", flexWrap: "wrap", aligmItems: "center" }}
        >
          <p style={{ width: "67px" }}>{title}:</p>

          <FontWeight style={{ fontSize: "14px", marginRight: "10px" }}>
            {name}
          </FontWeight>
          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            {email || number ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: "relative" }}>
                  <IconButton
                    onClick={handleClick}
                    size="large"
                    aria-label="copy"
                    className={personStyles.icons}
                  >
                    <ContactsIcon />
                  </IconButton>
                  <Popper id={id} open={open} anchorEl={anchorEl}>
                    <ContactsPopper
                      personEmail={email}
                      personPhone={number}
                      shouldShowActions={true}
                      personName={name}
                    />
                  </Popper>
                </Box>
              </ClickAwayListener>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default BriefDetail;
